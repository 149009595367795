import React from "react";
import {
	Image,
	Text,
	View,
	FlatList,
	Dimensions,
	TouchableOpacity,
	Animated,
	LayoutAnimation,
	Platform,
	UIManager,
	ScrollView,
} from "react-native";

import "./index.css";
import { Fade } from "react-reveal";
import CookieConsent from "react-cookie-consent";
import { api_updateUUIDData } from "../../Api";
import axios from "axios";
import { APP_COLOURS } from "../ChallengeApp/APP_VARIABLES";

// let product1 = {
// 	Dry: {
// 		text: "ADVANCED CERAMIDE Light",
// 		img: require("./ea_acl.png"),
// 	},
// 	Oily: {
// 		text: "ADVANCED CERAMIDE",
// 		img: require("./ea_ac.png"),
// 	},
// 	Combination: {
// 		text: "ADVANCED CERAMIDE",
// 		img: require("./ea_acl.png"),
// 	},
// };

// let product2 = {
// 	Hydration: {
// 		text: "HYALURONIC ACID",
// 		img: require("./ea_ha.png"),
// 	},
// 	Texture: {
// 		text: "RETINOL",
// 		img: require("./ea_re.png"),
// 	},
// 	Brightness: {
// 		text: "VITAMIN C",
// 		img: require("./ea_vc.png"),
// 	},
// };

let product1 = {
	Dry: {
		text: "ADVANCED CERAMIDE Light",
		img: require("../REVLON - EA - Ceramide Images/acl.png"),
	},
	Oily: {
		text: "ADVANCED CERAMIDE",
		img: require("../REVLON - EA - Ceramide Images/advanced.png"),
	},
	Combination: {
		text: "ADVANCED CERAMIDE",
		img: require("../REVLON - EA - Ceramide Images/advanced.png"),
	},
};

let product2 = {
	Hydration: {
		text: "HYALURONIC ACID",
		img: require("../REVLON - EA - Ceramide Images/hyl.png"),
	},
	Texture: {
		text: "RETINOL",
		img: require("../REVLON - EA - Ceramide Images/retinol.png"),
	},
	Brightness: {
		text: "VITAMIN C",
		img: require("../REVLON - EA - Ceramide Images/vitc.png"),
	},
};

class Page1 extends React.Component {
	render() {
		return (
			<Fade>
				<View
					style={{
						flex: 1,
						// width: window.innerWidth,
						width: "100%",
						// height: window.innerHeight - 100,
					}}>
					<View
						style={{
							width: "100%",
							// justifyContent: "center",
							alignItems: "center",
							flex: 1,
							padding: 50,
						}}>
						<h1>Which Ceramide </h1>
						<h1>
							Capsule <i>is Right</i>{" "}
						</h1>
						<h1>
							for <i>You?</i>{" "}
						</h1>
					</View>

					{/* <View style={{ width: "100%" }}>
						<TouchableOpacity
							style={{
								width: window.innerWidth,
								height: window.innerHeight,
								justifyContent: "center",
								alignItems: "center",
								overflow: "hidden",
							}}
							onPress={() => {}}>
							<View style={{}}>
								<Image
									source={require("./q1.png")}
									style={{
										width: window.innerWidth,
										height: window.innerHeight,
										resizeMode: "cover",
									}}
								/>
							</View>
						</TouchableOpacity>
					</View> */}
					<View
						style={{
							width: "100%",
							padding: 50,
							paddingTop: 40,
							paddingBottom: 10,
							// flex: 1,
						}}>
						<TouchableOpacity onPress={() => this.props.nextPage()}>
							<View
								style={{
									padding: 17,
									borderWidth: 1,
									justifyContent: "center",
									alignItems: "center",
								}}>
								<Text
									style={{
										fontFamily: "Gotham2",
										fontSize: 17,
										fontWeight: "500",
									}}>
									Let's begin
								</Text>
							</View>
						</TouchableOpacity>
					</View>

					{/* <View
						style={{
							width: "100%",
							justifyContent: "center",
							alignItems: "center",
							padding: 50,
							paddingBottom: 10,
							marginTop: 20,
						}}>
						<Text
							style={{
								// fontFamily: "Gotham",
								fontSize: 19,
								fontWeight: "300",
								textAlign: "center",
								// fontStyle: "italic",
							}}>
							Join our
						</Text>
						<Text
							style={{
								// fontFamily: "Gotham",
								fontSize: 19,
								fontWeight: "500",
								textAlign: "center",
								// fontStyle: "italic",
							}}>
							Transformation challenge
						</Text>
					</View> */}

					<Fade left>
						<View
							style={{
								width: "100%",
								marginTop: 40,
								marginBottom: 100,
							}}>
							{/* <TouchableOpacity onPress={() => this.props.nextPage()}>
							<Image
								source={require("./challenge.png")}
								style={{
									width: window.innerWidth - 10,
									height:
										(window.innerWidth - 10) * (226 / 606),
									resizeMode: "contain",
								}}
							/>
						</TouchableOpacity> */}
							{/* <TouchableOpacity
								onPress={() => {
									window.location = "./challenge";
								}}>
								<Image
									source={require("./challenge2.png")}
									style={{
										width: window.innerWidth - 20,
										height:
											(window.innerWidth - 20) *
											(343 / 606),
										resizeMode: "contain",
										marginBottom: 100,
									}}
								/>
							</TouchableOpacity> */}
						</View>
					</Fade>

					{/* <View
						style={{
							flex: 1,
						}}>
						<Image
							source={require("./q1.png")}
							style={{
								width: window.innerWidth,
								height: window.innerHeight * 0.4,
								resizeMode: "contain",
							}}
						/>
					</View> */}
				</View>
			</Fade>
		);
	}
}

class Page2 extends React.Component {
	render() {
		return (
			<Fade>
				<View
					style={{
						// width: window.innerWidth,
						width: "100%",
						height: window.innerHeight - 100,
					}}>
					<View
						style={{
							width: "100%",
							// justifyContent: "center",
							// alignItems: "center",
							padding: 50,
							paddingTop: 0,
						}}>
						<TouchableOpacity onPress={() => this.props.prevPage()}>
							<View
								style={{
									marginBottom: 25,
									opacity: 0.6,
								}}>
								<Image
									source={require("./back.png")}
									style={{
										width: 18,
										height: 20,
										resizeMode: "contain",
									}}
								/>
							</View>
						</TouchableOpacity>

						<Text
							style={{
								fontFamily: "Gotham2",
								fontSize: 17,
								fontWeight: "500",
							}}>
							Whats your
						</Text>
						<h1>
							Skin <i>Type?</i>{" "}
						</h1>

						<View
							style={{
								width: "100%",
								marginTop: 30,
							}}>
							<Fade cascade bottom>
								{["Dry", "Oily", "Combination"].map((d, i) => (
									<TouchableOpacity
										key={i}
										onPress={() => {
											this.props.nextPage({
												type: d,
												product1: product1[d].text,
											});
										}}>
										<View
											style={{
												padding: 17,
												borderWidth: 1,
												justifyContent: "center",
												alignItems: "center",
												marginBottom: 10,
												backgroundColor:
													this.props.type === d
														? "#000"
														: "#FFF",
											}}>
											<Text
												style={{
													fontFamily: "Gotham2",
													fontSize: 17,
													fontWeight: "500",
													color:
														this.props.type === d
															? "#FFF"
															: "#000",
												}}>
												{d}
											</Text>
										</View>
									</TouchableOpacity>
								))}
							</Fade>
						</View>
					</View>
				</View>
			</Fade>
		);
	}
}

class Page3 extends React.Component {
	render() {
		return (
			<Fade>
				<View
					style={{
						// width: window.innerWidth,
						width: "100%",
						height: window.innerHeight - 100,
					}}>
					<View
						style={{
							width: "100%",
							// justifyContent: "center",
							// alignItems: "center",
							padding: 50,
							paddingTop: 0,
						}}>
						<TouchableOpacity onPress={() => this.props.prevPage()}>
							<View
								style={{
									marginBottom: 25,
									opacity: 0.6,
								}}>
								<Image
									source={require("./back.png")}
									style={{
										width: 18,
										height: 20,
										resizeMode: "contain",
									}}
								/>
							</View>
						</TouchableOpacity>
						<Text
							style={{
								fontFamily: "Gotham2",
								fontSize: 17,
								fontWeight: "500",
							}}>
							Whats your
						</Text>
						<h1>
							Skin <i>Concern?</i>{" "}
						</h1>

						<View
							style={{
								width: "100%",
								marginTop: 30,
							}}>
							<Fade cascade bottom>
								{["Texture", "Brightness", "Hydration"].map(
									(d, i) => (
										<TouchableOpacity
											key={i}
											onPress={async () => {
												await this.props.nextPage({
													concern: d,
													product2: product2[d].text,
												});
											}}>
											<View
												style={{
													padding: 17,
													borderWidth: 1,
													justifyContent: "center",
													alignItems: "center",
													marginBottom: 10,
													backgroundColor:
														this.props.concern === d
															? "#000"
															: "#FFF",
												}}>
												<Text
													style={{
														fontFamily: "Gotham2",
														fontSize: 17,
														fontWeight: "500",
														color:
															this.props
																.concern === d
																? "#FFF"
																: "#000",
													}}>
													{d}
												</Text>
											</View>
										</TouchableOpacity>
									),
								)}
							</Fade>
						</View>
					</View>
				</View>
			</Fade>
		);
	}
}

class Page4 extends React.Component {
	product = (title, data) => {
		console.log(data);
		//
		return (
			<View
				style={{
					flex: 1,

					alignItems: "center",
					justifyContent: "center",
				}}>
				<Image
					source={data.img}
					style={{
						width: 100,
						height: 150,
						resizeMode: "contain",
					}}
				/>
				<View
					style={{
						marginTop: 20,
						padding: 10,
					}}>
					<Text
						style={{
							fontFamily: "Gotham",
							fontSize: 15,
							textAlign: "center",
						}}>
						{data.text}
					</Text>
				</View>
			</View>
		);
	};

	render() {
		return (
			<Fade>
				<View
					style={{
						// width: window.innerWidth,
						height: window.innerHeight,
						width: "100%",
					}}>
					<ScrollView>
						<View
							style={{
								width: "100%",
								// justifyContent: "center",
								alignItems: "center",
								// flex: 1,
								padding: 10,
							}}>
							<h1>Your Ceramide</h1>
						</View>

						<View
							style={{
								height: window.innerHeight * 0.5,
								// flex: 1,
								flexDirection: "row",
								padding: 20,
								paddingTop: 30,
								alignItems: "center",
								justifyContent: "center",
							}}>
							{this.product(
								"product1",
								product1[this.props.type],
							)}

							<View
								style={{
									padding: 5,
									alignItems: "center",
									justifyContent: "center",
								}}>
								<Text
									style={{
										fontSize: 30,
									}}>
									+
								</Text>
							</View>

							{this.product(
								"product2",
								product2[this.props.concern],
							)}
						</View>

						{localStorage.getItem("retailer") === "" ? (
							<View
								style={{
									flex: 1,
									alignItems: "center",
									justifyContent: "center",
								}}>
								{/* <View
									style={{
										width: "100%",
										padding: 10,
										alignItems: "center",
										justifyContent: "center",
									}}>
									<View
										style={{
											width: "100%",

											maxWidth: 350,
										}}>
										<Text
											style={{
												fontFamily: "Gotham",
												fontSize: 35,
												fontWeight: "500",
												textAlign: "center",
											}}>
											YOUR GIFT
										</Text>
									</View>
									<Image
										source={require("../../assets/gwp.png")}
										style={{
											width: "100%",
											height: 220,
											resizeMode: "contain",
										}}
									/>
									<View
										style={{
											width: "100%",

											maxWidth: 350,
										}}>
										<Text
											style={{
												fontFamily: "Gotham",
												fontSize: 17,
												fontWeight: "500",
												textAlign: "center",
											}}>
											Receive a 6-PIECE gift set when you
											spend R1,500 or more on any
											Elizabeth Arden skincare and any
											Elizabeth Arden 100 mL fragrance
										</Text>
									</View>

									<Image
										source={require("../../assets/gwp_plus.png")}
										style={{
											width: "100%",
											height: 220,
											resizeMode: "contain",
										}}
									/>
								</View> */}

								<View
									style={{
										width: "100%",
										padding: 20,
										alignItems: "center",
										justifyContent: "center",
									}}>
									<Text
										style={{
											fontFamily: "Gotham",
											fontSize: 17,
											fontWeight: "500",
										}}>
										Avaliable in-store & online
									</Text>
								</View>
								<View
									style={{
										padding: 10,
									}}>
									<View
										style={{
											width: "100%",
											flexDirection: "row",
											flexWrap: "wrap",
										}}>
										{/* {
												title: "takealot",
												url: "https://www.takealot.com/all?_sb=1&_r=1&_si=6ad9767de05f3083ffea0c0a19f708fb&qsearch=elizabeth%20arden",
											}, */}
										{[
											{
												title: "Dis-Chem",
												url: "https://www.dischem.co.za/catalogsearch/result/?q=Elizabeth+arden&form_key=",
											},
											{
												title: "Woolworths",
												url: "https://www.woolworths.co.za/cat?Ntt=Elizabeth%20arden&Dy=1",
											},
											{
												title: "Truworths",
												url: "https://www.truworths.co.za/elizabeth-arden/category/cat220subcat1367subcat1397?N=4040245682&Ns=s1&No=0&Nr=v&Nrpp=45&Ntt=&Nf=&ab=",
											},
											{
												title: "Foschini",
												url: "https://bash.com/s?search=elizabeth%20arden",
											},
											{
												title: "Edgars",
												url: "https://www.edgars.co.za/catalogsearch/result/index/?form_key=jMuzYb6qUISqv31P&p=3&q=elizabeth+arden",
											},

											{
												title: "Clicks",
												url: "https://clicks.co.za/brands/elizabeth-arden",
											},
										].map((d, i) => (
											<TouchableOpacity
												style={{
													margin: 1,
													width: "100%",
													maxWidth:
														window.innerWidth *
														0.45,
												}}
												onPress={() => {
													let retailer =
														localStorage.getItem(
															"retailer",
														);
													console.log(retailer);
													window.open(
														d.url,
														"_blank",
													);
												}}>
												<View
													style={{
														padding: 17,
														borderWidth: 1,
														justifyContent:
															"center",
														alignItems: "center",
													}}>
													<Text
														style={{
															fontFamily:
																"Gotham",
															fontSize: 17,
															fontWeight: "500",
														}}>
														{d.title}
													</Text>
												</View>
											</TouchableOpacity>
										))}
									</View>
								</View>
							</View>
						) : (
							<View
								style={{
									flex: 1,

									flexDirection: "row",
									padding: 20,
								}}>
								<View
									style={{
										flex: 1,
										alignItems: "center",
										justifyContent: "center",
									}}>
									<View
										style={{
											width: window.innerWidth * 0.35,
										}}>
										<TouchableOpacity
											style={{}}
											onPress={() => {
												let retailer =
													localStorage.getItem(
														"retailer",
													);
												console.log(retailer);
												if (retailer === "edgars") {
													window.open(
														"https://www.edgars.co.za/advanced-ceramide-capsules-daily-youth-restoring-face-serum-2642791501?gclid=Cj0KCQjw7PCjBhDwARIsANo7CgncadVCyUQ-Hd5E2HRyPWqHFxVYvALrQpK3aWkRtafLCDRZeLXfq2EaAkUfEALw_wcB",
														"_blank",
													);
												}
												if (retailer === "clicks") {
													window.open(
														"https://clicks.co.za/elizabeth-arden_advanced-ceramide-capsules-daily-youth-restoring-serum-60pc/p/343427?gclid=Cj0KCQjw7PCjBhDwARIsANo7Cgl-cBu_aDs59eTepJaxK65KzEieu0mMxASyFwBqaV-Fh3O9wd1mQlkaAj7nEALw_wcB",
														"_blank",
													);
												}
												if (retailer === "truworths") {
													window.open(
														"https://truworths.co.za",
														"_blank",
													);
												}
												if (retailer === "dischem") {
													window.open(
														"https://www.dischem.co.za/elizabeth-arden-advanced-ceramide-capsules-daily-youth-restoring-eye-serum-60pc-905",
														"_blank",
													);
												}
												if (retailer === "takealot") {
													window.open(
														"https://takealot.co.za",
														"_blank",
													);
												}
												if (
													retailer === "superbalist"
												) {
													window.open(
														"https://takealot.co.za",
														"_blank",
													);
												}
												if (retailer === "woolworths") {
													window.open(
														"https://woolworths.co.za",
														"_blank",
													);
												}
												if (retailer === "bash") {
													window.open(
														"https://bash.com",
														"_blank",
													);
												}
												if (retailer === "") {
													window.open(
														"https://bash.com",
														"_blank",
													);
												}
											}}>
											<View
												style={{
													padding: 17,
													borderWidth: 1,
													justifyContent: "center",
													alignItems: "center",
												}}>
												<Text
													style={{
														fontFamily: "Gotham",
														fontSize: 17,
														fontWeight: "500",
													}}>
													Buy Now
												</Text>
											</View>
										</TouchableOpacity>
									</View>
								</View>

								<View
									style={{
										padding: 5,
										alignItems: "center",
										justifyContent: "center",
									}}></View>

								<View
									style={{
										flex: 1,
										alignItems: "center",
										justifyContent: "center",
									}}>
									<View
										style={{
											padding: 10,
										}}>
										<View
											style={{
												width: window.innerWidth * 0.35,
											}}>
											<TouchableOpacity
												onPress={() => {
													let retailer =
														localStorage.getItem(
															"retailer",
														);
													console.log(retailer);
													if (
														retailer === "" ||
														typeof retailer ===
															"undefined"
													) {
														window.open(
															"https://www.elizabetharden.co.za/",
															"_blank",
														);
													}

													if (retailer === "edgars") {
														window.open(
															"https://www.edgars.co.za/advanced-ceramide-capsules-daily-youth-restoring-face-serum-2642791501?gclid=Cj0KCQjw7PCjBhDwARIsANo7CgncadVCyUQ-Hd5E2HRyPWqHFxVYvALrQpK3aWkRtafLCDRZeLXfq2EaAkUfEALw_wcB",
															"_blank",
														);
													}
													if (retailer === "clicks") {
														window.open(
															"https://clicks.co.za/elizabeth-arden_advanced-ceramide-capsules-daily-youth-restoring-serum-60pc/p/343427?gclid=Cj0KCQjw7PCjBhDwARIsANo7Cgl-cBu_aDs59eTepJaxK65KzEieu0mMxASyFwBqaV-Fh3O9wd1mQlkaAj7nEALw_wcB",
															"_blank",
														);
													}
													if (
														retailer === "truworths"
													) {
														window.open(
															"https://truworths.co.za",
															"_blank",
														);
													}
													if (
														retailer === "dischem"
													) {
														window.open(
															"https://www.dischem.co.za/elizabeth-arden-advanced-ceramide-capsules-daily-youth-restoring-eye-serum-60pc-905",
															"_blank",
														);
													}
													if (
														retailer === "takealot"
													) {
														window.open(
															"https://takealot.co.za",
															"_blank",
														);
													}
													if (
														retailer ===
														"superbalist"
													) {
														window.open(
															"https://takealot.co.za",
															"_blank",
														);
													}
													if (
														retailer ===
														"woolworths"
													) {
														window.open(
															"https://woolworths.co.za",
															"_blank",
														);
													}
													if (retailer === "bash") {
														window.open(
															"https://bash.com",
															"_blank",
														);
													}
												}}>
												<View
													style={{
														padding: 17,
														borderWidth: 1,
														justifyContent:
															"center",
														alignItems: "center",
													}}>
													<Text
														style={{
															fontFamily:
																"Gotham",
															fontSize: 17,
															fontWeight: "500",
														}}>
														Buy Now
													</Text>
												</View>
											</TouchableOpacity>
										</View>
										{/* <View
									style={{
										width: window.innerWidth * 0.35,
										marginTop: 20,
									}}>
									<TouchableOpacity
										onPress={() => this.props.nextPage()}>
										<View
											style={{
												padding: 17,
												borderWidth: 1,
												justifyContent: "center",
												alignItems: "center",
											}}>
											<Text
												style={{
													fontFamily: "Gotham",
													fontSize: 17,
													fontWeight: "500",
												}}>
												Start trial
											</Text>
										</View>
									</TouchableOpacity>
								</View> */}
									</View>
								</View>
							</View>
						)}

						{/* <View
						style={{
							width: "100%",
							justifyContent: "center",
							alignItems: "center",
							padding: 50,
							paddingBottom: 10,
							marginTop: 20,
						}}>
						<Text
							style={{
								// fontFamily: "Gotham",
								fontSize: 15,
								fontWeight: "300",
								textAlign: "center",
								// fontStyle: "italic",
							}}>
							Take our 3 Day Transformation challenge
						</Text>
					</View>

					<Fade left>
						<View
							style={{
								width: "100%",
								paddingTop: 10,
								marginBottom: 100,
							}}>
							<TouchableOpacity
								onPress={() => {
									window.location = "./challenge";
								}}>
								<Image
									source={require("./challenge2.png")}
									style={{
										width: window.innerWidth - 20,
										height:
											(window.innerWidth - 20) *
											(343 / 606),
										resizeMode: "contain",
										marginBottom: 100,
									}}
								/>
							</TouchableOpacity>
						</View>
					</Fade> */}

						{/* <View
						style={{
							width: "100%",
							// justifyContent: "center",
							alignItems: "center",
							// flex: 1,
							padding: 10,
							marginTop: 50,
						}}>
						<h1>Learn More</h1>
					</View>

					<View
						style={{
							width: "100%",
							// justifyContent: "center",
							alignItems: "center",
							// flex: 1,
							padding: 10,
							marginTop: 50,
						}}>
						<h1>How to use</h1>
					</View>

					<View
						style={{
							width: "100%",
							// justifyContent: "center",
							alignItems: "center",
							// flex: 1,
							padding: 10,
							marginTop: 50,
						}}>
						<h1>How to dispose</h1>
					</View> */}

						{/* <Image
						source={require("./guide.png")}
						style={{
							width: window.innerWidth,
							height: window.innerWidth * (1878 / 447),
							resizeMode: "contain",
							marginTop: 50,
						}}
					/> */}

						<View
							style={{
								height: window.innerHeight * 0.2,
							}}
						/>
					</ScrollView>
				</View>
			</Fade>
		);
	}
}

class Page5 extends React.Component {
	render() {
		return (
			<Fade>
				<View
					style={{
						width: window.innerWidth,
						height: window.innerHeight - 100,
					}}>
					<View
						style={{
							width: "100%",
							// justifyContent: "center",
							alignItems: "center",
							// flex: 1,
							padding: 10,
						}}>
						<h1>Your Ceramide</h1>
					</View>

					<View
						style={{
							// height: window.innerHeight * 0.6,
							flex: 1,
							flexDirection: "row",
							padding: 20,
							paddingTop: 30,
							alignItems: "center",
							justifyContent: "center",
						}}>
						<View
							style={{
								flex: 1,
								alignItems: "center",
								justifyContent: "center",
							}}>
							<Image
								source={require("./ea_acl.png")}
								style={{
									width: 100,
									height: 150,
									resizeMode: "contain",
								}}
							/>
							<View
								style={{
									marginTop: 20,
								}}>
								<Text
									style={{
										fontFamily: "Gotham",
										fontSize: 15,
										textAlign: "center",
										marginTop: 20,
									}}>
									ADVANCED CERAMIDE Light
								</Text>
							</View>
						</View>

						<View
							style={{
								padding: 5,
								alignItems: "center",
								justifyContent: "center",
							}}>
							<Text
								style={{
									fontSize: 30,
								}}>
								+
							</Text>
						</View>

						<View
							style={{
								flex: 1,
								alignItems: "center",
								justifyContent: "center",
							}}>
							<Image
								source={require("./ea_ha.png")}
								style={{
									width: 100,
									height: 150,
									resizeMode: "contain",
								}}
							/>
							<View
								style={{
									marginTop: 20,
									padding: 10,
								}}>
								<Text
									style={{
										fontFamily: "Gotham",
										fontSize: 15,
										textAlign: "center",
									}}>
									HYALURONIC ACID
								</Text>
							</View>
						</View>
					</View>

					<View
						style={{
							flex: 1,

							flexDirection: "row",
							padding: 20,
						}}>
						<View
							style={{
								flex: 1,
								alignItems: "center",
								justifyContent: "center",
							}}>
							<View
								style={{
									width: window.innerWidth * 0.35,
								}}>
								<TouchableOpacity
									onPress={() => this.props.nextPage()}>
									<View
										style={{
											padding: 17,
											borderWidth: 1,
											justifyContent: "center",
											alignItems: "center",
										}}>
										<Text
											style={{
												fontFamily: "Gotham",
												fontSize: 17,
												fontWeight: "500",
											}}>
											Buy Now
										</Text>
									</View>
								</TouchableOpacity>
							</View>
							{/* <View
								style={{
									width: window.innerWidth * 0.35,
									marginTop: 20,
								}}>
								<TouchableOpacity
									onPress={() => this.props.nextPage()}>
									<View
										style={{
											padding: 17,
											borderWidth: 1,
											justifyContent: "center",
											alignItems: "center",
										}}>
										<Text
											style={{
												fontFamily: "Gotham",
												fontSize: 17,
												fontWeight: "500",
											}}>
											Start trial
										</Text>
									</View>
								</TouchableOpacity>
							</View> */}
						</View>

						<View
							style={{
								padding: 5,
								alignItems: "center",
								justifyContent: "center",
							}}></View>

						<View
							style={{
								flex: 1,
								alignItems: "center",
								justifyContent: "center",
							}}>
							<View
								style={{
									padding: 10,
								}}>
								<View
									style={{
										width: window.innerWidth * 0.35,
									}}>
									<TouchableOpacity
										onPress={() => {
											let retailer =
												localStorage.getItem(
													"retailer",
												);
											console.log(retailer);
											if (retailer === "edgars") {
												window.open(
													"https://www.edgars.co.za/advanced-ceramide-capsules-daily-youth-restoring-face-serum-2642791501?gclid=Cj0KCQjw7PCjBhDwARIsANo7CgncadVCyUQ-Hd5E2HRyPWqHFxVYvALrQpK3aWkRtafLCDRZeLXfq2EaAkUfEALw_wcB",
													"_blank",
												);
											}
											if (retailer === "clicks") {
												window.open(
													"https://clicks.co.za/elizabeth-arden_advanced-ceramide-capsules-daily-youth-restoring-serum-60pc/p/343427?gclid=Cj0KCQjw7PCjBhDwARIsANo7Cgl-cBu_aDs59eTepJaxK65KzEieu0mMxASyFwBqaV-Fh3O9wd1mQlkaAj7nEALw_wcB",
													"_blank",
												);
											}
											if (retailer === "truworths") {
												window.open(
													"https://truworths.co.za",
													"_blank",
												);
											}
											if (retailer === "dischem") {
												window.open(
													"https://www.dischem.co.za/elizabeth-arden-advanced-ceramide-capsules-daily-youth-restoring-eye-serum-60pc-905",
													"_blank",
												);
											}
											if (retailer === "takealot") {
												window.open(
													"https://takealot.co.za",
													"_blank",
												);
											}
											if (retailer === "superbalist") {
												window.open(
													"https://takealot.co.za",
													"_blank",
												);
											}
											if (retailer === "woolworths") {
												window.open(
													"https://woolworths.co.za",
													"_blank",
												);
											}
											if (retailer === "bash") {
												window.open(
													"https://bash.com",
													"_blank",
												);
											}
										}}>
										<View
											style={{
												padding: 17,
												borderWidth: 1,
												justifyContent: "center",
												alignItems: "center",
											}}>
											<Text
												style={{
													fontFamily: "Gotham",
													fontSize: 17,
													fontWeight: "500",
												}}>
												Buy Now
											</Text>
										</View>
									</TouchableOpacity>
								</View>
								<View
									style={{
										width: window.innerWidth * 0.35,
										marginTop: 20,
									}}>
									<TouchableOpacity
										onPress={() => this.props.nextPage()}>
										<View
											style={{
												padding: 17,
												borderWidth: 1,
												justifyContent: "center",
												alignItems: "center",
											}}>
											<Text
												style={{
													fontFamily: "Gotham",
													fontSize: 17,
													fontWeight: "500",
												}}>
												Start trial
											</Text>
										</View>
									</TouchableOpacity>
								</View>
							</View>
						</View>
					</View>

					{/* <View
						style={{
							width: "100%",
							padding: 50,
							// flex: 1,
						}}>
						<TouchableOpacity onPress={() => this.props.nextPage()}>
							<View
								style={{
									padding: 17,
									borderWidth: 1,
									justifyContent: "center",
									alignItems: "center",
								}}>
								<Text
									style={{
										fontFamily: "Gotham2",
										fontSize: 17,
										fontWeight: "500",
									}}>
									Next
								</Text>
							</View>
						</TouchableOpacity>
					</View> */}
				</View>
			</Fade>
		);
	}
}

// Define Page3, Page4 and Page5 similarly

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentPage: 1,
			concern: "",
			type: "",
			sentUpdate: false,
		};
	}

	nextPage = async (selected) => {
		console.log(selected);

		await this.setState((prevState) => ({
			currentPage: prevState.currentPage + 1,
			...selected,
		}));

		if (this.state.concern !== "" && this.state.type !== "") {
			await console.log("updateState");
			await this.updateState(this.state);
		} else {
			console.log("no state update");
		}
	};

	prevPage = () => {
		this.setState((prevState) => ({
			currentPage: prevState.currentPage - 1,
		}));
	};

	pages = () => {
		let currentPage;

		switch (this.state.currentPage) {
			case 1:
				currentPage = (
					<Page1 {...this.state} nextPage={this.nextPage} />
				);
				break;
			case 2:
				currentPage = (
					<Page2
						{...this.state}
						prevPage={this.prevPage}
						nextPage={this.nextPage}
					/>
				);
				break;
			case 3:
				currentPage = (
					<Page3
						{...this.state}
						prevPage={this.prevPage}
						nextPage={this.nextPage}
					/>
				);
				break;
			case 4:
				currentPage = (
					<Page4
						{...this.state}
						prevPage={this.prevPage}
						nextPage={this.nextPage}
					/>
				);
				break;
			case 5:
				currentPage = (
					<Page5 {...this.state} prevPage={this.prevPage} />
				);
				break;
			default:
				currentPage = (
					<Page1 {...this.state} nextPage={this.nextPage} />
				);
				break;
		}
		return <View>{currentPage}</View>;
	};

	updateState = async (state) => {
		let analyticsData = await JSON.parse(
			localStorage.getItem("analyticsData"),
		);
		if (typeof analyticsData !== "undefined" && analyticsData !== null) {
			console.log("analyticsData", analyticsData);
			let raw = {
				uuid: analyticsData.uuid,
				...state,
			};
			console.log(api_updateUUIDData, raw);
			fetch(api_updateUUIDData, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(raw),
			})
				.then((response) => {
					response.json();
					if (response.status === 200) {
						analyticsData = {
							uuid: analyticsData.uuid,
							...analyticsData,
							...state,
						};
						const set_ = localStorage.setItem(
							"analyticsData",
							JSON.stringify(analyticsData),
						);
					}
				})
				.then((data) => {})
				.catch((error) => {
					console.error(error);
					this.setState({ isLoading: false });
				});
		} else {
			// window.location = "./";
		}
	};

	render() {
		let height = window.screen.height;
		return (
			<View
				style={{
					// flex: 1,
					backgroundColor: "#FFF",
					overflow: "hidden",
					height: height,
					width: window.innerWidth,
				}}>
				<ScrollView
					overScrollMode='never'
					showsVerticalScrollIndicator={false}>
					<View
						style={{
							flex: 1,
						}}>
						<View
							style={{
								height: 100,
								width: "100%",
								// backgroundColor: "red",
							}}>
							<View
								style={{
									position: "absolute",
									top: -100,
									left: -130,
								}}>
								<Image
									source={require("./cer_left.png")}
									style={{
										width: 247,
										height: 203,
										resizeMode: "contain",
									}}
								/>
							</View>

							<View
								style={{
									position: "absolute",
									top: -90,
									right: -50,
								}}>
								<Image
									source={require("./cer_right.png")}
									style={{
										width: 247,
										height: 215,
										resizeMode: "contain",
									}}
								/>
							</View>
						</View>

						<View
							style={{
								width: "100%",
								maxWidth: 450,
								marginHorizontal: "auto",
								zIndex: 999,
							}}>
							{this.pages()}
						</View>
					</View>
				</ScrollView>
				<TouchableOpacity
					onPress={() => {
						window.location.href = "/2axe/";
					}}>
					<View
						style={{
							position: "absolute",
							bottom: 0,
							left: 0,
						}}>
						<Image
							source={require("./cer_left.png")}
							style={{
								width: 247,
								height: 203,
								resizeMode: "contain",
							}}
						/>
					</View>
				</TouchableOpacity>

				<CookieConsent
					location='bottom'
					buttonText='Accept & continue'
					cookieName='CookieNotice'
					style={{ background: APP_COLOURS.BACKGROUND2 }}
					buttonStyle={{
						color: "#000",
						fontSize: "16px",
						borderRadius: 10,
						padding: 10,
					}}
					expires={150}>
					<View
						style={{
							flexDirection: "row",
						}}>
						<Text
							style={{
								color: "#FFF",
							}}>
							We use cookies to make your experience of our
							websites better. By using and further navigating
							this website you accept this. Detailed information
							about the use of cookies on this website is
							available by clicking on{" "}
							<TouchableOpacity
								style={{
									color: "#FFF",
									fontWeight: "600",
								}}
								onPress={() => {
									window.open(
										"https://acl.skintools.co.za/cookiepolicy",
										"blank",
									);
								}}>
								more information.{" "}
							</TouchableOpacity>
						</Text>
					</View>
				</CookieConsent>
			</View>
		);
	}
}

export default App;
