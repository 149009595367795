import React from "react";
import { useParams } from "react-router-dom";
import ArStandalone from "./ArStandalone";

export const ARurlChatBot = () => {
	// Extract the 'botid' parameter from the URL
	const { retailer } = useParams();

	return <ArStandalone retailer={retailer} />;
};
