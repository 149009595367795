import React, { Component, useState, useEffect } from "react";
import {
	View,
	TouchableOpacity,
	Image,
	Text,
	ActivityIndicator,
} from "react-native";
import { Modal } from "react-native-web";
// import OverlayComponent from "./OverlayArComp";
import AutoHeightImage from "../BeautyBot/AutoHeightImageComp";
import instruction from "./Launch.svg";
import { APP_COLOURS } from "../BeautyBot/APP_VARS";

export default class ArStandalone extends Component {
	constructor(props) {
		super(props);
		this.state = {
			videoStream: null,
			showSignup: false,
			showInstruction: true,
		};
	}

	async componentDidMount() {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({
				video: true,
				audio: false,
			});
			this.setState({ videoStream: stream });
		} catch (error) {
			console.error("Error accessing camera:", error);
		}
	}

	render() {
		return (
			<View
				style={{
					width: window.innerWidth,
					height: window.innerHeight,
				}}>
				<View
					style={{
						width: window.innerWidth,
						height: window.innerHeight,
						justifyContent: "center",
						alignItems: "center",
					}}>
					<View
						style={{
							flexDirection: "row",
							backgroundColor: APP_COLOURS.BACKGROUND,
							shadowColor: APP_COLOURS.BACKGROUND,
							shadowOffset: { width: 0, height: 8 },
							shadowOpacity: 0.14,
							shadowRadius: 9.32,
							elevation: 12,
							// top: this.state.keyboardActive ? 48 : 100,
							position: "absolute",
							width: "100%",
							top: 0,
							// display: "none",
							zIndex: 99,
							marginTop: 20,

							maxWidth: 500,
							borderRadius: 10,
							paddingRight: 10,
						}}>
						<View
							style={{
								// flex: 1,
								flexDirection: "row",
								alignItems: "center",
								// paddingRight: 5,
								width: "100%",
							}}>
							<View
								style={{
									justifyContent: "center",
									alignItems: "center",
									margin: 5,
									borderRadius: 30,
									borderWidth: 2,
									padding: 0.5,
									backgroundColor: "#FFF",
									// borderColor: "online",
									overflow: "hidden",
									marginRight: 9,
								}}>
								<AutoHeightImage
									width={40}
									source={require("../BeautyBot/Beauty-Bot-Lady.png")}
									style={{
										resizeMode: "contain",
										borderRadius: 25,
									}}
								/>

								{this.state.listeningForMessage ? (
									<View
										style={{
											padding: 10,
											justifyContent: "center",
											alignItems: "center",
											position: "absolute",
											top: 0,
											left: 0,
										}}>
										<ActivityIndicator
											color={APP_COLOURS.YELLOW}
										/>
									</View>
								) : null}
							</View>

							<View
								style={{
									flex: 1,
								}}>
								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
										//   marginBottom: 3,
									}}>
									<Text
										allowFontScaling={false}
										style={{
											fontWeight: "700",
											fontSize: 20,
											color: "#FFF",
										}}>
										#HyraplayYourWay
									</Text>
								</View>
							</View>

							<TouchableOpacity
								style={{}}
								onPress={async () => {
									window.location.href = `/hydraplaybot/${this.props.retailer}`;
								}}>
								<View
									style={{
										// height: 35,
										padding: 10,
										justifyContent: "center",
										alignItems: "center",
										backgroundColor: "red",
										borderRadius: 15,
									}}>
									<Text
										style={{
											color: "#FFF",
											fontSize: 15,
											fontFamily: "Gotham",
											fontWeight: "300",
										}}>
										Lets Chat!
									</Text>
								</View>
							</TouchableOpacity>
						</View>
					</View>

					<View
						style={{
							position: "absolute",
							top: 0,
							left: 0,
							zIndex: 2,
							width: window.innerWidth,
							height: window.innerHeight,
							// backgroundColor: "red",
							// opacity: 0,
						}}>
						<iframe
							src='https://mywebar.com/p/8HourHydraPlay'
							frameborder='0'
							seamless
							style={{
								display: "block",
								top: 0,
								width: "100%",
								height: window.innerHeight,
							}}
							allow='camera;gyroscope;accelerometer;magnetometer;xr-spatial-tracking;microphone;'></iframe>
					</View>

					{/* <OverlayComponent /> */}

					<View
						style={{
							position: "absolute",
							zIndex: 11,
							bottom: 0,
							width: "100%",
							// borderRadius: 15,
							// padding: 5,
							justifyContent: "center",
							alignItems: "center",
							overflow: "hidden",
						}}>
						{this.state.showInstruction ? (
							<Modal
								animationType='fade'
								transparent={true}
								visible={true}>
								<View
									style={{
										flex: 1,
										position: "absolute",
										zIndex: 999,
										backgroundColor:
											"rgba(52, 52, 52, 0.8)",
										width: window.innerWidth,
										height: window.innerHeight,
										justifyContent: "center",
										alignItems: "center",
									}}>
									<TouchableOpacity
										onPress={() => {
											this.setState({
												showInstruction: false,
											});
										}}>
										<View style={{}}>
											<Image
												source={instruction}
												style={{
													width: 251,
													height: 356,
													resizeMode: "contain",
												}}
											/>
										</View>
									</TouchableOpacity>
								</View>
							</Modal>
						) : null}

						{/* <View
								style={{
									overflow: "hidden",
									width: "100%",
									pointerEvents: "none", // Add this line
								}}>
								<img
									src={require("../cloudBottom.webp")}
									alt='Description'
									className='responsive'
								/>
							</View> */}
						{/* <View
							style={{
								width: "100%",
								backgroundColor: "white",
								justifyContent: "center",
								alignItems: "center",
								height: window.innerHeight * 0.2,
								padding: 20,
							}}>
							<Text
								style={{
									fontFamily: "Gotham",
									fontWeight: "700",
									fontSize: 12,
									color: "#000",
									textAlign: "center",
								}}>
								Question:
							</Text>
							<Text
								style={{
									fontFamily: "Gotham",
									fontWeight: "400",
									fontSize: 19,
									color: "#000",
									textAlign: "center",
								}}>
								How many benefits can you count
							</Text>
							<View
								style={{
									flex: 1,
									justifyContent: "space-between",
									alignItems: "center",
									flexDirection: "row",
								}}>
								{[1, 5, 8].map((d) => (
									<TouchableOpacity
										onPress={() => {
											// window.location.href =
											// 	"/signup";

											this.setState({
												showSignup: true,
											});
										}}>
										<View
											style={{
												// width: 40,
												width: 50,
												padding: 10,
												justifyContent: "center",
												alignItems: "center",
												backgroundColor: "#3C97C9",
												borderRadius: 10,
												marginRight: 10,
											}}>
											<Text
												style={{
													fontFamily: "Gotham",
													fontWeight: "400",
													fontSize: 19,
													color: "#FFF",
													textAlign: "center",
												}}>
												{d}
											</Text>
										</View>
									</TouchableOpacity>
								))}
							</View>
						</View> */}
					</View>
				</View>
			</View>
		);
	}
}
